html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(18rem / 16);
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
        line-height: calc(33/20);
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(30rem/16);
    line-height: calc(34/32);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: calc(60rem/16);
        line-height: calc(55/60);
    }
}
h2, .h2 {
    font-size: calc(30rem/16);
    line-height: calc(48/33);
    font-family: var(--font-default-bold);
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: calc(60rem/16);
        line-height: calc(65/60);
    }
}
h3, .h3 {
    font-size: calc(24rem/16);
    letter-spacing: calc(.8rem / 16);
    font-family: var(--font-arapey);
    font-weight: 400;
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        letter-spacing: unset;
        font-size: calc(35rem/16);
        line-height: calc(30/35);
    }
}
h4, .h4 {
    font-size: calc(22rem/16);
    line-height: calc(30/28);
    font-family: var(--font-default-bold);
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: calc(28rem/16);
    }
}
h5, .h5 {
    font-size: calc(24rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    font-weight: 400;
}
h6, .h6 {
    font-size: 1em;
}

.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.font-default-bold,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
.font-arapey {
    font-family: var(--font-arapey);
    font-weight: 400;
}