.hotspot {
    position: absolute;
    width: calc(18rem / 16);
    height: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        width: auto;
        height: auto;
    }
}
.is-open.hotspot {
    opacity: 1;
    z-index: 1;
}
.hotspot__btn {
    border-radius: calc(28rem / 16);
    background: var(--color-primary);
    padding: calc(6rem / 16) calc(8rem / 16);
    font-size: calc(12rem / 16);
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: #fff;
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(8rem / 16) calc(15rem / 16);
        display: inline-block;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
        padding: calc(10rem / 16) calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(20rem / 16);
        padding: calc(12rem / 16) calc(25rem / 16);
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        display: none;
        white-space: nowrap;
    }
}
.hotspot--ehrwald .hotspot__btn{ background: var(--color-ehrwald);}
.hotspot--lermoos .hotspot__btn{ background: var(--color-lermoos);}
.hotspot--berwang .hotspot__btn{ background: var(--color-berwang);}
.hotspot--bichlbach .hotspot__btn{ background: var(--color-bichlbach);}
.hotspot--biberwier .hotspot__btn{ background: var(--color-biberwier);}
.hotspot--heiterwang .hotspot__btn{ background: var(--color-heiterwang);}

.is-open .hotspot__btn {
    display: block;
}
@media screen and (max-width: 767px) {
    .hotspot__point:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: inline-block;
        border-radius: 50%;
        background: var(--color-primary);
        transition: opacity 200ms ease-in-out;
    }
    .hotspot--ehrwald .hotspot__point:before { background: var(--color-ehrwald);}
    .hotspot--lermoos .hotspot__point:before { background: var(--color-lermoos);}
    .hotspot--berwang .hotspot__point:before { background: var(--color-berwang);}
    .hotspot--bichlbach .hotspot__point:before { background: var(--color-bichlbach);}
    .hotspot--biberwier .hotspot__point:before { background: var(--color-biberwier);}
    .hotspot--heiterwang .hotspot__point:before { background: var(--color-heiterwang);}

    .is-open .hotspot__point {
        opacity: 0;
    }
    .hotspot__point:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        width: calc(6rem / 16);
        height: calc(6rem / 16);
        background: #fff;
    }
    .hotspot--sm .hotspot__point:before {
        background-color: rgba(0,0,0,0.2);
        border: calc(1rem / 16) solid #fff;
    }
}
.hotspot:focus {
    outline: none;
}

.hotspot--sm .hotspot__btn {
    font-family: var(--font-default);
    background-color: rgba(0,0,0,0.25);
    border: calc(1rem / 16) solid #fff;

    @media screen and (min-width: 768px) {
        padding: calc(6rem / 16) calc(8rem / 16);
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(16rem / 16);
        padding: calc(8rem / 16) calc(15rem / 16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(20rem / 16);
        padding: calc(12rem / 16) calc(25rem / 16);
    }
}

/* positions */
.hotspot--detail-left .hotspot__btn {
    left: auto;
    right: 0;
    transform: translateX(0) translateY(-50%);
}
.hotspot--detail-right .hotspot__btn {
    left: 0;
    transform: translateX(0) translateY(-50%);
}