.drag-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(56rem / 16);
    width: calc(56rem / 16);
    pointer-events: none;
}
@media (hover: none) and (pointer: coarse) {
    .drag-indicator {
        display: none;
    }
}

.drag-indicator__element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(.5);
    opacity: 0;
    transition: transform 120ms cubic-bezier(0, 0.47, 1, 1.35), opacity 120ms;
    border-radius: 50%;
    border: 1px solid var(--color-default);
    background: var(--color-primary);
    pointer-events: none;
    display: none;

    @media screen and (min-height: 768px) {
        display: block;
    }
}
.drag-indicator__element__icon {
    position: absolute;
    width: calc(20rem / 16);
    height: auto;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.drag-indicator.is-following .drag-indicator__element {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1) translateZ(0);
}
.drag-indicator.is-grabbed .drag-indicator__element {
    transform: translate(-50%, -50%) scale(.7);
}