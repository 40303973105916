.navbar {
    z-index: 3;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
        height: calc(60rem / 16);
    }
}
.navbar__hero {
    width: 100%;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.navbar__hero__link {
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: calc(34rem / 16);
    color: rgba(255, 255, 255, 0.7);
}
.navbar__hero__link:hover {
    color: rgba(255, 255, 255, 1);
}
.navbar__hero__link__icon {
    font-size: 2rem;
    margin: auto;
    vertical-align: -.5em;

    @media screen and (max-width: 992px) {
        font-size: calc(28rem / 16);
    }
}
.navbar__top {
    width: 100%;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
}
.navbar__top__logo {
    margin-left: 8rem;
    @media screen and (max-width: 1400px) {
        margin-left: 4rem;
    }
    @media screen and (max-width: 992px) {
        margin-left: 1rem;
    }
}
.navbar__top__link {
    margin-right: 5rem;
    color: rgba(90, 90 , 90, 0.2);
}
.navbar__top__link:hover {
    color: rgba(90, 90 , 90, 0.4);
}
.navbar__top__link__icon {
    font-size: 2rem;
    vertical-align: -.2em;
    @media screen and (max-width: 992px) {
        font-size: calc(28rem / 16);
    }
}
.navbar__top__btn {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @media screen and (max-width: 1200px) {
        font-size: 1.5rem;
    }
}
.navbar__top__btn__pale {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(7rem / 16) calc(30rem / 16);
    background-color: rgb(151, 191, 13, 0.7);
    color: rgba(255, 255, 255, 0.7);
}
.navbar__top__btn__pale:hover {
    background-color: rgb(151, 191, 13);
    color: rgba(255, 255, 255, 1);
}
.navbar__top__btn__icon{
    font-size: calc(26rem / 16);
    @media screen and (max-width: 1200px) {
        font-size: calc(18rem / 16);
    }
}

.navbar-brand {
    padding: 0;
    margin: 0;
    max-width: calc(70rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(140rem / 16);
    }
}
.navbar-brand__img {
    width: calc(70rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(140rem / 30);
    }
}
/* toggle button */
.navbar-toggler {
    cursor: pointer;
    border: 0;
    color: var(--color-default);
    padding: 0;
    font-size: calc(10rem / 16);
    height: calc(60rem / 16);
    width: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        margin: 0 auto;
        width: auto;
        height: auto;
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(18rem / 16);
        margin: calc(30rem / 16) auto 0;
    }
}
.navbar-toggler__text {
    margin-bottom: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        letter-spacing: calc(1rem / 16);
        margin-bottom: calc(6rem / 16);
    }

    @media screen and (min-width: 1400px) {
        letter-spacing: calc(2.8rem / 16);
    }
}
.navbar-toggler__bar {
    display: block;
    width: calc(30rem / 16);
    height: calc(2rem / 16);
    border-radius: calc(2rem / 16);
    background: var(--color-primary);
    background: var(--color-primary);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    transform-origin: left center;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 768px){
        width: calc(40rem / 16);
        height: calc(4rem / 16);
    }

    @media screen and (min-width: 1400px){
        width: calc(62rem / 16);
    }
}
.navbar-toggler__bar:not(:first-child) {
    margin-top: calc(5rem / 16);

    @media screen and (min-width: 1400px) {
        margin-top: calc(10rem / 16);
    }
}
.is-open .navbar-toggler__bar {
    transform: scaleX(.74) rotate(27deg) translateX(calc(8rem / 16)) translateY(calc(-3rem / 16));

    @media screen and (min-width: 1400px) {
        transform: scaleX(.74) rotate(27deg) translateX(13px) translateY(-7px);
    }
}
.is-open .navbar-toggler__bar:nth-child(2) {
    transform: scaleX(0);
    opacity: 0;
}
.is-open .navbar-toggler__bar:last-child {
    transform: scaleX(.74) rotate(-27deg) translateX(calc(8rem / 16)) translateY(calc(5rem / 16));

    @media screen and (min-width: 1400px) {
        transform: scaleX(.74) rotate(-27deg) translateX(13px) translateY(7px);
    }
}
.navbar-toggler:focus,
.navbar-toggler:hover {
    outline: none;
}
.js-affix-top-nav-bar {
    opacity: 0;
    height: 0;

    -webkit-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
    -ms-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
}

.js-affix-top-nav-bar.show {
    height: auto;
    opacity: 1;
}

@media screen and (min-width: 768px){
    .navbar__top {
        height: 7rem;
    }
    .navbar__top .main__nav {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .navbar__top .main__nav-item {
        font-family: var(--font-default);
        margin-top: auto;
        margin-bottom: auto;
        font-size: 1.5rem;
        position: relative;
        color: rgba(50, 50 , 50, 1);
        overflow: visible;
    }
    .navbar__top .main__nav-item:hover{
        background-color:  var(--color-primary);
        color: white;
    }
    .navbar__top .main__nav-item-underline {
        display: none
    }
    .navbar__top .main__nav-item:hover .main__nav-item-underline {
        display: none;
    }
    .navbar__top .main__nav-item:hover .nav-item__dropdown {
        visibility: visible;
        opacity: 1;
    }
    .navbar__top .nav-item__dropdown {
        display: block;
        background-color:  var(--color-primary);
        position: absolute;
        left: 0;
        top: 100%;
        opacity: 0;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        min-height: 100%;
        visibility: hidden;
    }
    .navbar__top .nav-item__dropdown .nav-link {
        background-color: white;
        color: black;
        font-weight: bold;
    }
    .navbar__top .nav-item__dropdown .nav-link:hover {
        background-color:  var(--color-primary);
        color: white;
    }
    .navbar__top .navbar__menu {
        text-align: center;
        width: 100%;
        margin-right: 8rem;
    }
    .navbar__top .navbar__menu .hero-brand__img {
        display: none;
    }
    .navbar__top .btn {
        height: 100%;
        background-color: rgb(151, 191, 13, 1);
        color: rgba(255, 255, 255, 1);
    }
}
@media screen and (max-width: 1200px) {
    .navbar__top {
        height: 5.5em;
    }
    .navbar__top .main__nav-item {
        font-size: 1.1rem;
    }
    .navbar__top .navbar__menu {
        margin-right: 2rem;
    }
}
@media screen and (max-width: 1400px) {
    .navbar__top .navbar__menu {
        margin-right: 4rem;
    }
}
