.colored-ribbon {
    width: 100vw;
    padding: 3rem 0;
}
.colored-ribbon-container {
    margin: 0 2rem;
    @media screen and (min-width: 768px) {
        margin: 0 auto;
        width: 46rem;
    }
}
.colored-ribbon-title {
    line-height: 1 !important;
}
.colored-ribbon-text {
    line-height: 1.3;
}
.colored-ribbon-button {
    @media screen and (min-width: 768px) {
        margin-left: 5rem;
    }
    white-space: nowrap;
}