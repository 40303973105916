.inline-nav {
    padding-top: calc(70rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(30rem / 16);
    }
}
.hero + .inline-nav {
    @media screen and (max-width: 767px) {
        padding-top: calc(30rem / 16);
    }
}
.inline-nav__item {
    position: relative;

    @media screen and (min-width: 768px){
        text-transform: uppercase;
        font-family: var(--font-default-bold);
        margin: 0 calc(20rem / 16);
    }

    @media screen and (min-width: 1200px) {
        margin: 0 calc(48rem / 16);
    }
}

.inline-nav__item:after {
    content: '';
    position: absolute;
    bottom: calc(-1rem / 16);
    left: 0;
    right: 0;
    height: calc(2rem / 16);
    background: var(--color-primary);
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px){
        height: calc(4rem / 16);
    }
}

.inline-nav__item.active:after,
.inline-nav__item:hover:after {
    transform: scaleX(1);
}

.inline-nav__item.active {
    @media screen and (max-width: 767px) {
        padding: 0 calc(2rem / 16);
        font-size: calc(22rem / 16);
    }
}

/* large inline nav */
.inline-nav.inline-nav--lg {
    padding-top: 0;
    margin: calc(50rem / 16) 0;
}
.inline-nav--lg .inline-nav__item {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 786px) {
        font-size: calc(28rem / 16);
        text-transform: none;
    }
}

.inline-nav__item__arrow {
    font-size: calc(8rem / 16);
    position: absolute;
    right: calc(10rem / 16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
}
.show .inline-nav__item__arrow {
    transform: translateY(-50%) scaleY(-1);
}
.dropdown-menu .inline-nav__item {
    font-size: calc(16rem / 16);
    padding: calc(4rem / 16) calc(15rem / 16);
}