.author-teaser:not(:first-child){
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-top: calc(24rem / 16);
    padding-top: calc(28rem / 16);

    @media screen and (min-width: 768px){
        margin-top: calc(38rem / 16);
        padding-top: calc(40rem / 16);
    }
}

.author-teaser__img {
    max-width: 100%;
    border-radius: 100%;
    width: calc(147rem / 16);
    transition: transform 200ms ease-in-out;
}
.author-teaser:hover .author-teaser__img {
    transform: scale(.97);
}