.range-slider {
    height: calc(50rem / 16);
    padding: calc(25rem / 16) calc(15rem / 16) calc(4rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(80rem / 16);
    }
}
.range-slider .noUi-base:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: calc(1rem / 16) dashed var(--color-primary);
}
.range-slider__values {
    font-size: calc(12rem / 16);
    margin-bottom: calc(5rem / 16);
}
.range-slider__slider {
    height: calc(2rem / 16);
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: #fff;
}
.range-slider__slider .noUi-handle {
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    border: calc(1rem / 16) solid var(--color-dark);
    background: #fff;
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;
    top: calc(-20rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
        top: calc(-25rem / 16);
    }
}
.range-slider__slider .noUi-handle:focus {
    outline: none;
}
.range-slider__slider .noUi-handle:focus:after {
    background: var(--color-primary-dark);
}
.range-slider__slider .noUi-handle:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: calc(20rem / 16);
    height: calc(20rem / 16);
    background: var(--color-primary);
    border-radius: 50%;
    border: calc(1rem / 16) solid var(--color-dark);

    @media screen and (min-width: 768px){
        width: calc(25rem / 16);
        height: calc(25rem / 16);
    }
}
.range-slider__slider:not(.input-enabled) .noUi-handle:before {
    content: attr(data-text);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    width: auto;
}
html:not([dir=rtl]) .range-slider__slider.noUi-horizontal .noUi-handle {
    right: calc(-20rem / 16);

    @media screen and (min-width: 768px) {
        right: calc(-25rem / 16);
    }
}
.range-slider__slider .noUi-connect {
    background: var(--color-primary);
    box-shadow: none;
    height: calc(2rem / 16);
}

/* Chrome, Safari, Edge, Opera */
.range-slider input::-webkit-outer-spin-button,
.range-slider input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.range-slider input[type=number] {
    -moz-appearance: textfield;
}

.range-slider input.js-range-slider-input__min,
.range-slider input.js-range-slider-input__max {
    position: absolute;
    width: 60px;
    bottom: 24px;
    text-align: center;
    height: 28px;
    padding-top: 8px;
    @media screen and (max-width: 768px) {
        bottom: 0;
    }
}

.range-slider input.js-range-slider-input__min {
    left: 6px;
}

.range-slider input.js-range-slider-input__max {
    right: 6px;
}
