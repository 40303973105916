.img-slide {
    position: relative;
}
.img-slide__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(20rem / 16);
    font-size: calc(18rem / 16);
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        padding: calc(58rem / 16) calc(50rem / 16);
        font-size: calc(20rem / 16);
        line-height: calc(33/20);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(108rem / 16) calc(100rem / 16);
    }
}
.img-slide__content--center {
    padding: calc(10rem / 16) calc(40rem / 16);
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
}
.img-slide__badge {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    padding: calc(4rem / 16) calc(30rem / 16);
    font-size: calc(25rem / 16);
    line-height: calc(40/25);
}
.img-slide__content__title {
    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
        line-height: calc(40/50);
    }
}

.img-slide__content--sm {
    text-shadow: calc(1rem / 16) calc(1rem / 16) 0 #000;

    @media screen and (min-width: 768px) {
        text-shadow: calc(1rem / 16) calc(2rem / 16) 0 #000;
    }
}
.img-slide__content--sm .img-slide__content__title {
    font-size: calc(24rem / 16);
    line-height: calc(40/35);

    @media screen and (min-width: 768px) {
        font-size: calc(35rem / 16);
    }
}
.img-slide__content--sm .img-slide__content__subtitle {
    font-size: calc(18rem / 16);
    line-height: calc(33/30);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
.img-slide__content__title-lg {
    font-size: calc(30rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(100rem / 16);
    }
}
.img-slide__content--sm .btn {
    text-shadow: none;
}

/* slide content mobile relative */
@media screen and (max-width: 767px) {
    .img-slide__content--relative-xs {
        position: relative;
        background: var(--color-light-grey);
        color: var(--color-default);
    }
}