.hero__teaser {
    position: relative;
    border: calc(2rem / 16) solid #fff;
    margin: calc(-1rem / 16) calc(-2rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0 calc(-2rem / 16) 0 0;
    }
}
.hero__teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(45deg,  rgba(0,0,0,.8) 0%,rgba(0,0,0,0) 100%);
}
.is-active.hero__teaser:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(6rem / 16);
    background: var(--color-primary);
    z-index: 1;
}
.hero__teaser__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: calc(15rem / 16);
    font-size: calc(16rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        padding: calc(24rem / 16) calc(8rem / 16) calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(20rem / 16);
    }
}
.hero__teaser__img {
    transition: transform 200ms ease-in-out;
}
.hero__teaser:hover .hero__teaser__img {
    transform: scale(1.05);
}
.hero__teaser__content__title {
    font-size: calc(24rem / 16);
    line-height: calc(35/30);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
.hero__teaser--weather .hero__teaser__content {
    @media screen and (min-width: 1200px) {
        padding: calc(15rem / 16);
    }
    @media screen and (min-width: 1400px) {
        padding: calc(28rem / 16);
    }
}