.person-item .img-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0;
    transition: all .25s ease-in-out;
}
.person-item:hover .img-top {
    display: inline;
    opacity: 1;
}
