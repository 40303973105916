.decorated-hl {
    position: relative;
    padding-bottom: calc(22rem / 16);
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(48rem / 16);
        margin-bottom: calc(40rem / 16);
    }
}
.decorated-hl:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(3rem / 16);
    width: calc(134rem / 16);
    background: var(--color-primary);


    @media screen and (min-width: 768px) {
        height: calc(6rem / 16);
        width: calc(207rem / 16);
    }
}
/*.decorated-hl--sm {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(20rem / 16);
        padding-bottom: calc(20rem / 16);
    }
}
.decorated-hl--sm:after {
    @media screen and (min-width: 768px) {
        max-width: 70%;
        height: calc(4rem / 16);
    }
}*/
.text-left .decorated-hl:after {
    transform: none;
    left: 0;
}