.ui-datepicker {
    z-index: 1050 !important; /* datepicker in bootstrap modal */
}
.datepicker-container:after {
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);
    pointer-events: none;
}
.ui-datepicker {
    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
    }
}