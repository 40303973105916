.floating-label {
    position: absolute;
    left: 0;
    top: calc(5rem / 16);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(calc(-12rem / 16)) scale(.75);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(calc(-12rem / 16)) scale(.75);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-12rem / 16)) scale(.75);
}

/* style for bordered inputs */
.floating-label--in-border {
    left: calc(20rem / 16);
    top: calc(15rem / 16);
}
.form-control:focus ~ .floating-label--in-border,
select ~ .floating-label--in-border,
.form-control.has-value ~ .floating-label--in-border,
.form-group.has-value .floating-label--in-border {
    transform: translateY(calc(-8rem / 16)) scale(.75);
}