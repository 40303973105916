.separator__container {
    display: flex;
    justify-content: center;
    width: 100%;

    .separator {
        display: flex;
        justify-content: center;
        max-height: 4rem;
    }
}
.separator__symbol {
    margin: auto 1rem;
    max-height: 4rem;
    min-width: 2rem;
}
.separator__bar {
    max-height: 4rem;

    &.mirrored {
        transform: scaleX(-1);
    }
}