.teaser {
    position: relative;
}
.teaser__body {
    margin-top: calc(14rem / 16);
    font-size: calc(18rem / 16);
    line-height: calc(30/18);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        line-height: calc(30/20);
        margin-top: calc(20rem / 16);
    }
}
.teaser__body__title {
    font-size: calc(22rem / 16);
    line-height: calc(30/22);
    letter-spacing: unset;

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
        letter-spacing: calc(.8rem / 16);
    }
}

.teaser__badge {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}