.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}

.content-block {
    display: flex;
    flex-direction: column;
}

.content-block .pimcore_area_content {
    order: 2;
}