.form-control {
    height: calc(38rem / 16);
    padding: calc(6rem / 16) 0 0;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        height: calc(48rem / 16);
    }
}
.form-group {
    margin-bottom: calc(25rem / 16);
}
.form-control--bordered {
    background: #fff;
    border: calc(1rem / 16) solid var(--color-default);
    padding: calc(8rem / 16) calc(20rem / 16);
}
.form-control--bordered:focus {
    background: #fff;
}
textarea.form-control--bordered {
    padding: calc(30rem / 16) calc(20rem / 16);
}

.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: var(--color-default);
}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--color-default);
    opacity:  1;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-default);
}

.file-input__icon {
    position: absolute;
    right: calc(2rem / 16);
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(16rem / 16);
}