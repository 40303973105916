.faq-btn-main .icon-addthis {
    font-size: 20px;
    position: absolute;
    left: -2rem;
}

.faq-btn {
    height: 100%;
    padding-left: 1rem;
    width: 4rem;
    position: absolute;
}
.faq-btn .icon-addthis {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
}

.faq-btn-main {
    cursor: pointer;
}

.faq-btn-main .icon-addthis {
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
}

.faq-block-border {
    border-top: 1px solid #d8d8d8;
}
.faq-question {
    margin: 1.5rem 0 1rem 4rem;
}
.faq-answer {
    margin-left: 4rem;
}
.js-faq-block .js-faq-answer {
    overflow: hidden;
    max-height: 0;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
}
.js-faq-block .js-faq-toggler:hover {
    cursor: pointer;
    color: var(--color-primary) !important;
}
.js-faq-block.is-open .js-faq-toggler {
    color: var(--color-primary) !important;
}
.js-faq-block.is-open .icon-addthis{
    transform: translateY(-50%) rotate(45deg);
}

.js-faq-main-toggler.is-open .icon-addthis{
    transform: rotate(45deg);
}

.js-faq-block.is-open .js-faq-answer {
    padding: .5rem 0 1.5rem 0;
    max-height: 500px;
}

.js-faq-block .js-faq-answer {
    overflow: hidden;
    max-height: 0;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
}

.faq-block-collection {
    overflow: hidden;
    max-height: 0;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
}

.faq-block-collection.is-open {
    max-height: 2500px;
}