.lightbox-item {
    cursor: pointer;
    position: relative;
}
.lightbox-item__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: calc(22rem / 16) calc(20rem / 16);

    @media screen and (min-height: 992px) {
        padding: calc(52rem / 16) calc(50rem / 16);
    }
}
.lightbox-item__content__title {
    font-size: calc(28rem / 16);
    line-height: calc(40/50);

    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
    }
}
.lightbox-item__content__subtitle{
    font-size: calc(20rem / 16);
    line-height: calc(43/30);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}

/* lightgallery iframe */
.lg-has-iframe {
    max-width: calc(1500rem / 16) !important;
    height: 80% !important;

    @media screen and (min-width: 768px) {
        height: auto !important;
    }
}

.lg-has-iframe .lg-video {
    height: 80%;

    @media screen and (min-width: 768px) {
        height: 0 !important;
    }
}

.lg-outer .lg-img-wrap {
    padding: calc(10rem / 16) calc(5rem / 16) calc(80rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(5rem / 16) calc(100rem / 16);
    }
}