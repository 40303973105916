.value-text {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}
.value-text__value {
    font-size: calc(26rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(40rem / 16);
    }
}