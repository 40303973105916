.citation{
    position: relative;
    margin: calc(125rem / 16) 0 calc(55rem / 16);
    padding: calc(100rem/16) calc(20rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px){
        position: relative;
        padding: calc(105rem/16) calc(55rem / 16) calc(55rem / 16);
    }
}

.citation__img {
    max-width: 100%;
    position: absolute;
    left: 50%;
    border-radius: 100%;
    transform: translateX(-50%) translateY(-50%);
    top: 0;
    width: calc(150rem / 16);
}