.slider {
    line-height: 0;
}
.slick-slide > div {
    width: 100%;
}
.slider .slick-arrow {
    padding: calc(8rem / 16);

    @media screen and (min-width: 786px) {
        padding: calc(16rem / 16);
    }
}
.slider .slick-arrow:before {
    font-size: calc(20rem / 16);
}
.slider .slick-arrow.slick-disabled {
    cursor: default;
}
.slider .slick-prev {
    left: 0;

    @media screen and (min-width: 768px) {
        left: calc(24rem / 16);
    }
}
.slider .slick-next {
    right: 0;

    @media screen and (min-width: 768px) {
        right: calc(24rem / 16);
    }
}

.slider--arrows-outside {
    width: calc(1615rem / 16);
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    @media screen and (min-width: 768px) {
        padding: 0 calc(90rem / 16);
    }
}
.slider--arrows-outside .slick-arrow.slick-prev {
    left: 0;
}
.slider--arrows-outside .slick-arrow.slick-next {
    right: 0;
}


/* slider dots */
.slider .slick-dots {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem / 16);
    }
}
.slider .slick-dots>li>button {
    font-size: 0;
    padding: 0;
    width: calc(14rem / 16);
    height: calc(14rem / 16);
    background: var(--color-grey);
}
.slider .slick-dots>li {
    margin-left: calc(5rem / 16);
    margin-right: calc(5rem / 16);
}

.slick-dots--default {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}
.slick-dots--default>li>button {
    font-size: 0;
    padding: 0;
    width: calc(14rem / 16);
    height: calc(14rem / 16);
    background: var(--color-grey);
    border-radius: 50%;
    border: 0;
}
.slick-dots--default>.slick-active button {
    background: var(--color-primary);
}
.slick-dots--default>li {
    margin-left: calc(5rem / 16);
    margin-right: calc(5rem / 16);
}

/* slider gutters */
[class*=" slider--gutter-"] .slick-track {
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide {
    height: auto;
    display: flex;
}
[class*=" slider--gutter-"] .slick-slide .slider-item {
    height: 100%;
}
.slider--gutter-base .slick-list {
    margin: 0 calc(-15rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0 calc(-20rem / 16);
    }
}
.slider--gutter-base .slick-slide .slider-item {
    padding: 0 calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(20rem / 16);
    }
}
.slider--gutter-width-2 .slick-list {
    margin: 0 calc(-1rem / 16);
}
.slider--gutter-width-2 .slick-slide > div {
    padding: 0 calc(1rem / 16);
}

.slider-info-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        padding: 0 calc(20rem / 16);
    }
}
.slider-info {
    position: relative;
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.slider-info:after {
    content: '';
    height: 1px;
    position: absolute;
    left: 70%;
    right: -9999em;
    top: calc(40rem / 16);
    background: linear-gradient(90deg,transparent,transparent 92%,currentColor 0,currentColor) repeat-x 0 100%;
    background-size: calc(21rem / 16) calc(1rem / 16);
}
.slider-info__circle {
    width: calc(54rem / 16);
    height: calc(54rem / 16);
    border-radius: 50%;
    position: relative;
    border: calc(1rem / 16) dashed var(--color-default);
    margin-bottom: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(12rem / 16);
        width: calc(76rem / 16);
        height: calc(76rem / 16);
    }
}
.slider-info__circle__icon{
    position: absolute;
    width: calc(16rem / 16);
    height: auto;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(20rem / 16);
    }
}
.slider-info__circle:after {
    content: '';
    border-radius: 50%;
    display: block;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    background: var(--color-primary);
    border: calc(1rem / 16) solid var(--color-default);
    transition: transform 200ms ease-in-out;


    @media screen and (min-width: 768px) {
        width: calc(56rem / 16);
        height: calc(56rem / 16);
    }
}

.is-dragging + .slider-info-container .slider-info__circle:after, .is-dragging + .slider-info-container .slider-info__circle__icon {
    transform: scale(0);
}


/* New Teaser variants*/
.teaser-wrapper-layout .slider-item .teaser__body{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.teaser-wrapper-layout .slider-item .teaser__body .teaser__body__title{
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: white;
    z-index: 2;
}

.teaser-wrapper-layout .slider-item .teaser__tag {
    color: white;
    position: absolute;
    bottom: 90px;
    left: 30px;
    padding: 2px 10px;
    background-color: var(--color-primary);
    font-size: 14px;
    z-index: 2;
}
.teaser-wrapper-layout .teaser__decorater{
    width: 10rem;
    height: .35rem;
    position: absolute;
    bottom: 22px;
    left: 30px;
    z-index: 2;
    background-color: var(--color-primary);
}
.teaser-wrapper-layout .teaser:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .45;
    background: -webkit-gradient(linear,left top,left bottom,from(transparent),to(#000));
    background: -o-linear-gradient(top,transparent 0,#000 100%);
    background: linear-gradient(180deg,transparent,#000);
    z-index: 1;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}
.teaser-wrapper-layout .teaser:hover::after {
    opacity: .66;
}

.teaser-wrapper-layout .slider-item .teaser__body .btn{
    display: none;
}

.teaser-wrapper-layout .slider-item .teaser > div,
.teaser-wrapper-layout .slider-item .teaser .embed-responsive{
    height: 100%;
}

.teaser-wrapper-layout--s-w-s {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto;
    gap: 15px;
}

.teaser-wrapper-layout--w-s {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    gap: 15px;
}

.teaser-wrapper-layout--w {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 15px;
}

.teaser-wrapper-layout--w-s-s {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 15px;
}

.teaser-wrapper-layout--s-s-w {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: auto;
    gap: 15px;
}

.teaser-wrapper-layout--w-s-s-s-s-w {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 15px;
}

@media only screen and (max-width: 800px) {
    .teaser-wrapper-layout {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .teaser-wrapper-layout .teaser-layout-index {
        width: 48.5%;

    }
    .teaser-wrapper-layout .teaser-layout-index:nth-child(3n+3) {
        width: 100%;
    }
    .teaser-wrapper-layout .slider-item .teaser__tag {
        bottom: 70px;
    }
}

@media only screen and (max-width: 500px) {
    .teaser-wrapper-layout .teaser-layout-index {
        width: 100%;
    }
}

.teaser-wrapper-layout--w-s-s-s-s-w .teaser-layout-index {
    grid-column: span 3;
}
.teaser-wrapper-layout--w-s-s-s-s-w .teaser-layout-index-5,
.teaser-wrapper-layout--w-s-s-s-s-w .teaser-layout-index-0 {
    grid-column: span 6;
}

.object-fit-cover {
    object-fit: cover;
}

