.horizontal-teaser {
    margin: calc(30rem / 16) 0;
    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        line-height: calc(30/20);
        margin: calc(60rem / 16) 0;
    }
}
.horizontal-teaser__title {
    font-size: calc(22rem / 16);
    line-height: calc(40/30);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
.horizontal-teaser__subtitle {
    font-size: calc(20rem / 16);
    line-height: calc(30/24);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}