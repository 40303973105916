.blog-teaser {
    position: relative;
}
.blog-teaser:after {
    content: '';
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(30deg, rgba(0,0,0,.75) 0%,rgba(0,0,0,0) 50%);
}
.blog-teaser__img {
    transition: transform 200ms ease-in-out;
}
.blog-teaser:hover .blog-teaser__img {
    transform: scale(1.05);
}
.blog-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: calc(10rem / 16) calc(15rem / 16);
    font-size: calc(20rem / 16);
    line-height: calc(35/30);
    font-family: var(--font-arapey);

    @media screen and (min-width: 768px) {
        padding: calc(16rem / 16) calc(28rem / 16);
        font-size: calc(24rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }
}
.blog-teaser__content__title {
    font-size: calc(24rem / 16);
    line-height: calc(40/35);

    @media screen and (min-width: 768px) {
        font-size: calc(28rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(35rem / 16);
    }
}