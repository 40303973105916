.footer-main__block {
    padding: calc(35rem / 16) calc(5rem / 16);
    border-top: calc(1rem / 16) solid var(--color-light-grey);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(5rem / 16);
    }
}
.footer-main__block--lg {
    @media screen and (min-width: 768px) {
        padding: calc(75rem / 16) 0;
    }
}
.footer-main__block a:not(.btn):hover {
    color: var(--color-primary);
}
.footer-main {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(50rem / 16);
    }
}
.footer-icon__slider {
    display: flex;
}
.footer-icon__scroll-window {
    margin: auto;
    width: min(80%, 250px);
    height: 100px;
    overflow-x: auto;
    position: relative;

    &::-webkit-scrollbar {
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        width: 60%;
        height: 60%;
        background-color: #eceef2;
        border-radius: 180px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9b9b9b;
        border-radius: 180px;
    }

    @media screen and (max-width: 768px) {
        max-width: 250px;
    }
}

.footer-icon__container {
    cursor:hand;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
}
.footer-icon-wrapper {
    margin: auto 0;
}
.footer-icon {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
}
.footer-icon__container__draggable {
    cursor: move;
    cursor: url(images/grab.cur);
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.footer-icon__container__draggable:active {
    cursor: url(images/grabbing.cur);
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}