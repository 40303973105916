@media screen and (max-width: 767px) {
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        border-bottom: 1px solid var(--color-grey);
    }

    .navbar__left {
        background: #fff;
        margin-top: calc(59rem / 16);
        padding: calc(15rem / 16);
        z-index: 15;
        border-bottom: 1px solid var(--color-grey);
        border-right: 1px solid var(--color-grey);
    }

    .navbar-brand {
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 10;
        margin: 0 auto;
        img {
            transition: height .25s ease-in-out;
            height: 100px;
            width: auto;
        }
    }

    .navbar__left img {
        margin-top: calc(-59rem / 16);
    }

    .navbar__main {
        height: calc(100vh - calc(60rem / 16));
    }

    .navbar__main .navbar-collapse {
        padding: calc(100rem / 16) 0 calc(20rem / 16);
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .navbar__main__bottom-links {
        font-size: calc(24rem / 16);
    }

    .nav-item__dropdown {
        background: #fff;
        padding: calc(100rem / 16) calc(15rem / 16) calc(30rem / 16);
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        height: calc(100vh - calc(60rem / 16));
    }

    /* nav links */
    .navbar-nav .nav-link {
        padding: 0;
        font-size: calc(24rem / 16);
    }

    .nav-item__dropdown__title {
        padding: calc(5rem / 16) 0;
        font-size: calc(20rem / 16);
    }

    .navbar-nav .nav-item + .nav-item {
        margin-top: calc(22rem / 16);
    }

    .nav-item__dropdown__title {
        position: relative;
        padding-bottom: calc(10rem / 16);
        margin-bottom: calc(20rem / 16);
    }

    .nav-item__dropdown__title:after {
        content: '';
        height: calc(2rem / 16);
        border-radius: calc(1rem / 16);
        background-color: var(--color-primary);
        bottom: 0;
        left: 0;
        width: calc(50rem / 16);
        position: absolute;
    }

    .nav-item__dropdown__back__icon {
        font-size: calc(8rem / 16);
        margin-right: calc(5rem / 16);
        transform: rotate(90deg);
    }

    .nav-item__dropdown .nav-link {
        font-size: calc(18rem / 16);
    }

    .nav-item__dropdown .nav-item + .nav-item {
        margin-top: calc(12rem / 16);
    }

    .navbar__btn {
        padding: calc(14rem / 16);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
