.social-list {
    margin: calc(-8rem / 16) calc(-10rem / 16) 0;

    @media screen and (max-width: 767px) {
        justify-content: center;
    }
}
.social-list__item {
    margin-top: calc(8rem / 16);
}
.social-list__item {
    margin-right: calc(10rem / 16);
    margin-left: calc(10rem / 16);
}
.social-list__item__icon {
    font-size: calc(35rem / 16);
}

.social-list__svg:hover > g {
    fill: #0b72b5;
}

.social-list-container {
    max-width: calc(170rem / 16);
}
