.btn {
    font-family: var(--font-default);
    font-weight: 400;
    font-size: calc(20rem / 16);
    line-height: calc(29/20);
    padding: calc(6rem / 16) calc(20rem / 16);
    padding-bottom: 0.15rem;

    @media screen and (min-width: 768px) {
        padding: calc(9rem / 14) calc(30rem / 16) calc(8rem / 18);
    }
}
.btn-sm {
    font-size: calc(18rem / 16);
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        padding: calc(11rem / 16) calc(30rem / 16) calc(10rem / 16);
    }
}
.btn-lg {
    @media screen and (min-width: 768px) {
        padding: calc(9rem / 16) calc(75rem / 16) calc(8rem / 16);
    }
}
.btn-no-styling,
.btn.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

/* icon in button */
.btn__icon {
    vertical-align: -0.15em;
    font-size: calc(16rem / 16);
    margin-right: calc(5rem / 16);
}

/* icon button */
.icon-btn {
    height: calc(56rem / 16);
    width: calc(56rem / 16);
    border-radius: calc(4rem / 16);
    font-size: calc(18rem / 16);
    position: relative;
    padding: calc(5rem / 16);
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/* play button */
.play-btn{
    background: none;
    text-align: left;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    position: relative;
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    border-radius: 50%;
    padding: 0;
    background: var(--color-primary);
    border: calc(1rem / 16) solid var(--color-dark);
    margin: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(82rem / 16);
        height: calc(82rem / 16);
        margin: calc(20rem / 16);
    }
}
.play-btn:hover:after {
    transform: scale(.9);
}
.play-btn:after {
    content: '';
    position: absolute;
    top: calc(-14rem / 16);
    bottom: calc(-14rem / 16);
    left: calc(-14rem / 16);
    right: calc(-14rem / 16);
    border: calc(1rem / 16) solid var(--color-dark);
    border-radius: 50%;
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        top: calc(-20rem / 16);
        bottom: calc(-20rem / 16);
        left: calc(-20rem / 16);
        right: calc(-20rem / 16);
    }
}
.play-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: calc(26rem / 16);
    transform: translateX(-35%) translateY(-50%);

    @media screen and (min-width: 768px) {
        font-size: calc(33rem / 16);
    }
}
.play-btn--sm {
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    margin: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(64rem / 16);
        height: calc(64rem / 16);
        margin: calc(14rem / 16);
    }
}
.play-btn--sm:after {
    top: calc(-10rem / 16);
    bottom: calc(-10rem / 16);
    left: calc(-10rem / 16);
    right: calc(-10rem / 16);

    @media screen and (min-width: 768px) {
        top: calc(-14rem / 16);
        bottom: calc(-14rem / 16);
        left: calc(-14rem / 16);
        right: calc(-14rem / 16);
    }
}
.play-btn--sm .play-btn__icon {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}
