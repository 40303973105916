.thumb-slider {
    position: relative;
    @media screen and (min-width: 768px) {
        margin-bottom: calc(100rem / 16);
    }
}
.thumb-slider__image__bg {
    background: var(--color-light-grey);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.thumb-slider__image__bg--contain {
    background-size: contain;
}
.thumb-slider__image:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(45deg,  rgba(0,0,0,.8) 0%,rgba(0,0,0,0) 100%);
}
.thumb-slider__thumbs {
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
    width: 65%;
    padding: 0 calc(50rem / 16);
}
.thumb-slider__thumbs .slick-track {
    margin-right: 0;
    background: #fff;
    padding: calc(2rem / 16) calc(1rem / 16);
}
.thumb-slider__thumbs__item {
    position: relative;
    font-size: calc(20rem / 16);
    line-height: 1;
}
.thumb-slider__thumbs__item:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(45deg,  rgba(0,0,0,.8) 0%,rgba(0,0,0,0) 100%);
}
.thumb-slider__thumbs__item:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(6rem / 16);
    background: var(--color-primary);
    transition: transform 200ms ease-in-out;
    transform: scaleX(0);
    z-index: 1;
}
.slick-current .thumb-slider__thumbs__item:before {
    transform: scaleX(1);
}
.thumb-slider__thumbs__item__img {
    transition: transform 200ms ease-in-out;
}
.thumb-slider__thumbs__item:hover .thumb-slider__thumbs__item__img {
    transform: scale(1.05);
}
.thumb-slider__thumbs__item__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: calc(12rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(18rem / 16) calc(20rem / 16);
    }
}

.thumb-slider__thumbs .slick-arrow {
    bottom: calc(22rem / 16);
    top: auto;
    transform: none;
    color: var(--color-primary);
}
.thumb-slider__thumbs .slick-arrow.slick-prev {
    left: calc(-72rem / 16);
}
.thumb-slider__thumbs .slick-arrow.slick-next {
    right: calc(-72rem / 16);
}

.thumb-slider__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: calc(22rem / 16) calc(20rem / 16);
    font-size: calc(18rem / 16);
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        padding: calc(100rem / 16) 0;
        font-size: calc(20rem / 16);
        line-height: calc(33/20);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(120rem / 16) 0;
    }
}
.thumb-slider__content__title {
    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
        line-height: calc(40/50);
    }
}

/* slider animation */
.thumb-slider .img-slide__content {
    transition: transform 700ms ease-in-out, opacity 700ms ease-in-out;
    opacity: 0;
    transform: translateX(calc(-60rem / 16));
    transition-delay: 180ms;
}
.thumb-slider .slick-current .img-slide__content {
    opacity: 1;
    transform: translateX(0);
}

/* full width slider */
.thumb-slider--full-width .thumb-slider__thumbs {
    padding: 0;
    width: 58%;

    @media screen and (min-width: 768px) {
        max-width: calc(844rem / 16);
    }

    @media screen and (min-width: 1200px) {
        width: 65%;
    }
}
.thumb-slider--full-width .slick-arrow {
    opacity: 0;
}