.img-teaser {
    position: relative;
}
.img-teaser:after {
    content: '';
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(45deg,  rgba(0,0,0,.3) 0%,rgba(0,0,0,0) 100%);
}
.img-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-teaser:hover .img-teaser__img {
    transform: scale(1.05);
}
.img-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: calc(10rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(40rem / 16);
    }
}
.img-teaser__content__title {
    font-size: calc(13rem / 16);
    line-height: calc(40/35);
    text-transform: uppercase;
    word-break: break-word;
    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(24rem / 16);
    }
}

.img-teaser__content__title.hyphens-auto {
    hyphens: auto;
    -webkit-hyphens: auto;
}

.img-teaser__badge {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

/* small content */
.img-teaser:after {
    background: linear-gradient(45deg,  rgba(0,0,0,.8) 0%,rgba(0,0,0,0) 100%);
}
.img-teaser--md .img-teaser__content {
    padding: calc(15rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(24rem / 16) calc(30rem / 16);
        font-size: calc(22rem / 16);
        line-height: calc(30/22);
    }
}
.img-teaser--md .img-teaser__content__title {
    text-transform: none;

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(35rem / 16);
    }
}

/* extra small content */
.img-teaser--sm .img-teaser__content {
    padding: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        line-height: calc(26/20);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(24rem / 16) calc(30rem / 16);
    }
}
.img-teaser--sm .img-teaser__content__title {
    text-transform: none;
    font-size: calc(24rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(30rem / 16);
    }
}
