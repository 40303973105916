.info-row {
    line-height: calc(33/20);
}
.info-row__col {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px){
        flex-grow: 1;
        padding: calc(8rem / 16) 0;
    }
}
.info-row__col:not(:first-child) {
    @media screen and (min-width: 768px) {
        margin-left: calc(42rem / 16);
        padding-left: calc(40rem / 16);
    }
}
.info-row__col:not(:first-child):before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: calc(1rem / 16);
        background: rgba(255,255,255,.2);
    }
}
.info-row__col__value {
    font-size: calc(30rem / 16);
    line-height: calc(40/50);

    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
    }
}
.info-row__col__icon {
    font-size: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(72rem / 16);
    }
}

/* small infos */
.info-row--sm {
    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(23/16);
    }
}
.info-row--sm .info-row__col {
    @media screen and (min-width: 768px) {
        flex-grow: 1;
    }

    @media screen and (max-width: 767px){
        width: 33%;
        padding: calc(5rem / 16) 0;
    }
}
.info-row--sm .info-row__col:not(:first-child) {
    @media screen and (min-width: 768px) {
        margin-left: 19px;
        padding-left: 19px;
    }
}
.info-row--sm .info-row__col__value {
    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
        line-height: 1;
    }
}
.info-row--sm .info-row__col__icon {
    @media screen and (min-width: 768px) {
        font-size: calc(44rem / 16);
    }
}