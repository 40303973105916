.circle-teaser {
    font-size: calc(20rem / 16);
    line-height: calc(22/20);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}
.circle-teaser__text {
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    transform: translateY(0);
}
.circle-teaser__title {
    transition: transform 300ms ease-in-out;
}
.circle-teaser__img {
    max-width: 100%;
    padding: calc(16rem / 16);
    position: relative;
    transition: transform 300ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
        width: calc(312rem / 16);
    }
}
.circle-teaser__img:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--color-grey);
    border-radius: 50%;
    z-index: -1;
    transition: transform 300ms ease-in-out;
}

/* slider animation */
@media screen and (min-width: 1480px) {
    .slider-container-circle {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }
}


.circle-slider .slick-list {
    overflow: visible;
    padding: 0 calc(60rem / 16) !important;

    @media screen and (min-width: 1200px) {
        padding: 0 calc(100rem / 16) !important;
    }
}

.slick-slide:not(.slick-current) .circle-teaser__text {
    opacity: 0;
    transform: translateY(calc(-10rem / 16));
}
.slick-slide:not(.slick-current) .circle-teaser {
    transform: scale(.8);
    opacity: .3;

    @media screen and (min-width: 768px) {
        transform: scale(.65);
        transform-origin: left center;
        opacity: 1;
    }
}
@media screen and (min-width: 768px) {
    .circle-slider {
        /* max-width: calc(1110rem / 16); */
        margin: 0 auto;
    }
    .circle-teaser__text {
        margin: 0 calc(-40rem / 16);
    }

    .slick-slide.is-near-current .circle-teaser {
        transform: scale(.8);
    }
    .slick-current ~ .slick-slide .circle-teaser {
        transform-origin: right center;
    }
}

.circle-slider .slick-arrow {
    color: var(--color-primary);
    outline: var(--color-primary);
}