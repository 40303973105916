.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
    stroke: var(--color-primary);
}

.ct-tooltip {
    position: absolute;
    display: inline-block;
    min-width: calc(120rem / 16);
    background: var(--color-primary);
    color: #fff;
    text-align: center;
    pointer-events: none;
    z-index: 100;
    transition: opacity .2s linear;
    font-size: calc(13rem / 16);
    padding: calc(4rem / 16);
}
.ct-tooltip:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
    border: calc(7rem / 16) solid transparent;
    border-top-color: var(--color-primary);
    transform: translateX(-50%);
    pointer-events: none;
}
.ct-tooltip.hide {
    display: block;
    opacity: 0;
    visibility: hidden;
}
.ct-axis-title {
    fill: var(--color-dark-grey);
    font-size: calc(14rem/16);
}
.ct-chart .ct-label.ct-horizontal.ct-end:after,
.ct-chart .ct-label.ct-vertical.ct-start:after {
    content: 'm';
    position: relative;
    top: 0;
    font-size: calc(12rem / 16);
    margin-left: calc(2rem / 16);
}
.ct-chart .ct-label.ct-horizontal.ct-end:after {
    content: 'km';
}
.ct-label.ct-horizontal.ct-end {
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.ct-line {
    stroke-width: calc(3rem / 16);
}
.ct-point {
    stroke-width: calc(8rem / 16);
}

.tour-map {
    z-index: 1;
}