.state-circle {
    width: calc(15rem / 16);
    height: calc(15rem / 16);
    position: relative;
    border-radius: 50%;
}
.state-circle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(10rem / 16);
}

.state-circle--lg {
    width: calc(28rem / 16);
    height: calc(28rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(32rem / 16);
        height: calc(32rem / 16);
        vertical-align: -.2em;
    }
}
.state-circle--lg .state-circle__icon {
    font-size: calc(13rem / 16);
}

.state-circle__icon-container {
    position: absolute;
    right: calc(-10rem / 16);
    bottom: calc(-2rem / 16);
    background: #fff;
    border-radius: 50%;
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    box-shadow: 0 0 calc(2rem / 16) rgba(0,0,0,.4);
}
.bg-success .state-circle__icon-container {
    color: var(--color-success);
}
.bg-danger .state-circle__icon-container {
    color: var(--color-danger);
}
.state-circle__icon.state-circle__icon--sm {
    font-size: calc(7rem / 16);
}