.cse-teaser {
    margin: calc(30rem / 16) 0;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        line-height: calc(30/20);
        margin: calc(60rem / 16) 0;
    }
}
.cse-teaser__hl {
    font-size: calc(22rem / 16);
    line-height: calc(40/30);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}