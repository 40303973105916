.btn-no-styling,
mark {
  background: none;
  padding: 0;
}

.btn-no-styling {
  border: none;
  text-align: left;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.btn-no-styling.d-block {
  width: 100%;
}

body {
  font-weight: 400;
}

hr {
  border-top-color: #bfbfbf;
}

.hr--bold {
  border-top-width: 5px;
}

.hr--light {
  border-top-color: #f2f2f2;
}

.hr--border-gray {
  border-top-color: #d8d8d8;
}

.bg-light-gray {
  background: #f2f2f2;
}

.bg-white {
  background: #fff;
}

.box-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.card {
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.step-progress__bar {
  display: flex;
}

.step-progress__bar-item {
  height: 100%;
  -ms-flex: 1 1 0px;
  flex: 1 1 0%;
}

.row--gutter-width-6 {
  margin-left: -3px;
  margin-right: -3px;
}

.row--gutter-width-6 > [class*=' col'],
.row--gutter-width-6 > [class^='col'] {
  padding-left: 3px;
  padding-right: 3px;
}

.bordered {
  border: 1px solid #bfbfbf;
}

.border-right {
  border-right: 1px solid #d8d8d8;
}

.center-container {
  text-align: center;
  font-size: 0;
}

.center-container__item {
  display: inline-block;
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
}

.center-container__item-wrapper {
  position: relative;
  display: inline-block;
  max-height: 100%;
}

.center-container__item-wrapper img {
  display: block;
  margin: 0 auto;
}

.btn-show-gallery-container {
  position: relative;
}

.btn-show-gallery {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  color: #fff !important;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.6) !important;
}

.btn-show-gallery__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn-show-gallery--small {
  width: 30px;
  height: 30px;
  font-size: 16px;
}

.btn-no-styling {
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.circle-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-top: 2px solid #0b72b5;
  border-right: 2px solid transparent;
  animation: b 0.6s linear infinite;
}

.circle-spinner--small {
  width: 14px;
  height: 14px;
}

.circle-spinner--white {
  border-top-color: #fff;
}

.form-box__content.form-box__content,
.form-control.form-control {
  font-family: BrandonGrotesqueWeb-Regular, sans-serif;
  font-weight: 400;
}

.brochure-title {
  font-family: BrandonGrotesqueWeb-Black, sans-serif;
}

.form-box {
  background: #fff;
  text-align: left;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #bfbfbf;
  padding: 0;
  line-height: 1;
  position: relative;
}

.form-box,
.form-box .form-control {
  cursor: pointer;
}

.form-box__label {
  display: block;
  color: #595959;
  padding: 10px;
  padding-bottom: 0;
}

.form-box__content {
  display: block;
  font-family: BrandonGrotesqueWeb-Black, sans-serif;
  font-weight: 400;
  font-size: 17px;
  padding: 10px;
  padding-top: 8px;
  padding-right: 24px;
}

.form-box__content,
.form-box__content:focus,
.form-box__content[readonly] {
  background: transparent;
}

.form-box input,
.form-box select {
  padding-left: 10px;
  padding-right: 10px;
}

.form-control.form-box__content,
input.form-box__content,
select.form-box__content,
textarea.form-box__content {
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  margin-bottom: -1px;
}

.form-box__content.input-group,
.form-box__content.input-group input,
.form-box__content.input-group select,
.form-box__content.input-group select:not([size]):not([multiple]),
input.form-box__content,
select.form-box__content,
select.form-box__content:not([size]):not([multiple]) {
  height: 36px !important;
  padding: 4px 30px 4px 10px;
}

.form-box__content select,
.form-box__content select:focus,
select.form-box__content,
select.form-box__content:focus {
  padding-top: 2px;
  padding-bottom: 6px;
  padding-right: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-box__content select::-ms-expand,
select.form-box__content::-ms-expand {
  display: none;
}

.form-box__content.input-group {
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  background: transparent;
  margin-bottom: -1px;
}

.form-box__content.input-group select {
  padding-top: 6px;
  padding-bottom: 2px;
}

.form-box__icon {
  color: #0b72b5;
  position: absolute;
  right: 9px;
  bottom: 9px;
  font-size: 16px;
}

.form-box__icon.demi-icon-chevron-down {
  font-size: 6px;
  bottom: 12px;
}

.form-box__select-icon {
  position: absolute;
  bottom: 15px;
  right: 10px;
  pointer-events: none;
  font-size: 7px;
}

.form-box__select-icon--small {
  font-size: 5px;
}

.form-box--collapsible:not(.collapsed) {
  border-bottom-color: #0b72b5;
}

.form-box--collapsible:not(.collapsed) .form-box__content {
  color: #0b72b5;
}

.form-box--collapsible .form-box__icon {
  transition: transform 0.12s ease-out;
}

.form-box--collapsible:not(.collapsed) .form-box__icon {
  transform: rotate(180deg);
}

.form-box__detail {
  background: #fff;
}

.form-box--gray {
  background: #f2f2f2;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.form-box--gray .form-control:-webkit-autofill {
  box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
}

.form-box--readonly {
  opacity: 0.6;
}

.form-box:after {
  content: '\EA5A';
  font-family: demiIcons;
  color: #f95500;
  position: absolute;
  top: 9px;
  right: 7px;
  font-size: 12px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: scale(0);
  transform-origin: center;
  opacity: 0;
  transition: opacity 0.12s,
    transform 0.12s cubic-bezier(0.16, 0.46, 0.83, 1.36);
}

.has-error .form-box:after {
  opacity: 1;
  transform: scale(1);
}

.has-error .form-box__content,
.has-error .input-group .form-control {
  border-color: #f95500;
}

.has-error .input-group .form-control,
.has-success .form-box__content {
  border-color: #97bf0d;
}

.btn {
  border-radius: 0;
  font-family: BrandonGrotesqueWeb-Regular, sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.footer-main .btn,
.navbar__top__btn,
.slide--quickfinder .btn {
  font-size: 1.25rem;
  line-height: 1.45;
  padding: 0.375rem 1.25rem;
}

.footer-main .btn,
.navbar__top__btn,
.slide--quickfinder .btn {
  padding: 0.5625rem 1.875rem 0.5rem;
}

.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border: none;
}

.btn-default.active {
  color: #fff;
  background-color: #0b72b5;
}

.btn-block {
  display: block;
  width: 100%;
  white-space: normal;
}

.slide--quickfinder .btn__icon {
  vertical-align: -0.15em;
  font-size: 1rem;
  margin-right: 0.3125rem;
}

@font-face {
  font-family: demiIcons;
  src: url('/static/fonts/demiIcons.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.demi-icon {
  display: inline-block;
  font-family: demiIcons;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn__icon {
  font-size: 16px;
  vertical-align: -0.2em;
}

.btn__icon.demi-icon-arrow-right {
  font-size: 0.94em;
  vertical-align: -0.1em;
}

.demi-icon-arrow-left:before {
    content: "\EA04";
    font-family: demiIcons
}

.demi-icon-arrow-right:before {
    content: "\EA05";
    font-family: demiIcons
}

.btn__icon.demi-icon-facebook {
  font-size: 20px;
}

.btn__icon.demi-icon-person {
  vertical-align: -0.15em;
}

.btn__icon.demi-icon-cart {
  vertical-align: -0.1em;
}

.demi-icon-star:before {
  content: '\EA51';
  font-family: demiIcons;
}

.demi-icon-search:before {
  content: '\EA4C';
  font-family: demiIcons;
}

.demi-icon-check:before {
  content: '\EA12';
  font-family: demiIcons;
}

.demi-icon-info-circle-outline:before {
  content: '\EA30';
  font-family: demiIcons;
}

.demi-icon-check-circle:before {
    content: "\EA11";
    font-family: demiIcons
}

.toggle-btn,
.toggle-btn-wrapper {
  max-width: 100%;
}

.btn-default {
  color: #333;
  background-color: #e6e6e6;
}

.btn-default.active {
  color: #fff;
  background-color: #0b72b5;
}

.toggle-btn--highlighted {
  color: #fff;
  background-color: #0b72b5;
}

input:checked + .toggle-btn--highlighted {
  background-color: #97bf0d;
}

.toggle-btn--has-icon .toggle-btn__content {
  display: inline-block;
  transition: transform 0.12s;
  transform: translateX(-10px);
  vertical-align: middle;
  white-space: normal;
}

.toggle-btn--has-icon.toggle-btn--aligned-left .toggle-btn__content {
  transform: translateX(-20px);
}

input:checked + .toggle-btn--has-icon .toggle-btn__icon {
  opacity: 1;
  transform: scale(1);
}

input:checked + .toggle-btn--has-icon .toggle-btn__content {
  transform: translateX(0);
}

.demi-icon-close-circle:before {
  content: '\EA1B';
  font-family: demiIcons;
}

.toggle-btn__icon {
  width: 20px;
  text-align: left;
  transition: opacity 0.12s,
    transform 0.15s cubic-bezier(0.16, 0.46, 0.83, 1.36);
  transform: scale(0);
  color: #fff;
  vertical-align: middle;
}

input:checked + .toggle-btn--has-icon .toggle-btn__icon {
  opacity: 1;
  transform: scale(1);
}

.brochures .brochures-title {
  font-size: 36px;
  font-weight: 400;
  line-height: 32px;
}

.brochures .brochures-subitle {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}

@media screen and (max-width: 768px) {
  .brochures .brochures-title {
    font-size: 30px;
    line-height: 32px;
  }

  .brochures .brochures-subtitle {
    line-height: 22px;
  }
}

@media screen and (min-width: 768px) {
  .link-icon__icon {
    margin: 0 0.625rem 0 0;
    display: inline-block;
    font-size: 2.5rem;
    vertical-align: -0.3em;
  }

  .step-progress__icon-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .step-progress__icon-number {
    font-size: 16px;
  }
  .step-progress__icon {
    font-size: 14px;
    vertical-align: -0.1em;
    background: #e6e6e6;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    font-weight: 400;
  }
  .step-progress__bar-item {
    position: relative;
    text-align: center;
  }
  .step-progress__bar-item:after {
    content: '';
    background-color: #e6e6e6;
    height: 3px;
    width: 100%;
    position: absolute;
    right: 50%;
    top: 14px;
  }
  .step-progress__bar-item.is-done .step-progress__icon {
    background-color: #97bf0d;
  }
  .step-progress__bar-item.is-done .step-progress__text {
    color: #97bf0d;
  }
  .step-progress__bar-item.is-active .step-progress__icon {
    background-color: #0b72b5;
    color: #fff;
  }
  .step-progress__bar-item.is-active .step-progress__text {
    color: #0b72b5;
  }
  .step-progress__bar-item.is-done:after {
    background-color: #97bf0d;
  }
  .step-progress__bar-item.is-active:after {
    background: linear-gradient(90deg, #97bf0d, #0b72b5);
  }
  .step-progress__bar-item:first-child:after {
    display: none;
  }
  .step-progress__text {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 400;
    color: #8c8c8c;
  }

  .row--gutter-width-60 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row--gutter-width-60 > [class*=' col'],
  .row--gutter-width-60 > [class^='col'] {
    padding-left: 34px;
    padding-right: 34px;
  }

  .card-body {
    padding: 1.5rem;
    display: block;
  }

  .bordered {
    border: 1px solid #d8d8d8;
  }
}

@media (min-width: 1200px) {
  .row--gutter-width-xl-60 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row--gutter-width-xl-60 > [class*=' col'],
  .row--gutter-width-xl-60 > [class^='col'] {
    padding-left: 30px;
    padding-right: 30px;
  }
  .map-tooltip {
    font-size: 13px;
  }
}

@media screen and (min-width: 767px) {
  .form-box__label {
    font-size: 13px;
  }
}

/* dodatna stilizacija */
.brochures .form-control {
  border: none;
}

.form-box__icon.demi-icon-chevron-down {
  font-size: 6px;
  bottom: 12px;
}

.demi-icon-chevron-down:before {
  content: '\EA13';
  font-family: demiIcons;
}

.brochures .btn-default {
  border: none;
}

.brochures .btn-default,
.btn-default:hover,
.brochures .btn-default:not(:disabled):not(.disabled):active,
.brochures .btn-default,
.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
}

.brochures .toggle-btn--highlighted {
  color: #fff;
  background-color: #0b72b5;
}

.brochures input[type='checkbox']:disabled ~ .toggle-btn--highlighted {
  background-color: #6caad2 !important;
}

.btn__icon.demi-icon-arrow-right {
  font-size: 0.94em;
  vertical-align: -0.1em;
}

.demi-icon-arrow-right:before {
  content: '\EA05';
  font-family: demiIcons;
}

.brochures-checkout .btn-success,
.brochures .btn-success {
  background-color: #97bf0d !important;
  color: #fff;
}

.brochures-checkout .btn-success:active,
.brochures .btn-success:active {
  background-color: #97bf0d !important;
  color: #fff;
}

.brochures-checkout .btn-success:hover,
.brochures .btn-success:hover {
  background-color: #88ac0c !important;
  color: #fff;
}

.brochures-checkout .btn-success:focus,
.brochures .btn-success:focus {
  outline: none !important;
  box-shadow: 0 0 0 3px rgb(151 191 13 / 50%) !important;
}

.fancybox-thumbs {
  top: auto !important;
  width: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 95px !important;
  padding: 10px 10px 5px 10px !important;
  box-sizing: border-box !important;
  background: rgba(0, 0, 0, 0.3) !important;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 0 !important;
  bottom: 95px !important;
}

.fancybox-thumbs__list a {
  opacity: 0.5 !important;
}

.fancybox-thumbs__list a.fancybox-thumbs-active {
  opacity: 1 !important;
}

.fancybox-thumbs__list a:before {
  border: none !important;
}

@media (max-width: 767px) {
  .step-progress__bar {
    display: -ms-flexbox;
    display: flex;
    height: 5px;
    background-color: #e6e6e6;
    border-radius: 5px;
    overflow: hidden;
  }

  .step-progress__bar-item.is-active {
    background: #97bf0d;
    height: 100%;
    border-left: none;
  }

  .step-progress__bar-item + .step-progress__bar-item {
    border-left: 1px solid #8c8c8c;
  }

  .row--gutter-width-60 {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.form-control--gray,
.form-control--gray:focus {
  background-color: #f2f2f2;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.form-control--gray:-webkit-autofill {
  box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
}

.brochures-checkout .btn-default:active {
  color: #333 !important;
  background-color: #e6e6e6 !important;
  border: none;
}

.brochures-checkout .btn-default:focus {
  border: none !important;
  box-shadow: none !important;
}

.success-icon__bubble {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #97bf0d;
    border-radius: 50%
}
.success-icon__bubble,
.success-icon__icon {
    margin-left: 4px;

    
}

.success-icon__icon {
    font-size: 60px;
    vertical-align: middle;
    color: #97bf0d;
}

.success-icon__bubble,
    .success-icon__icon {
        margin-left: 4px;

}

.brochures-checkout #preferences {
    padding: 0.375rem;
}