.nav-tabs .nav-link {
    position: relative;
    padding: 0;
    padding-bottom: calc(3rem / 16);
    margin: calc(5rem / 16) calc(10rem / 16);
    color: var(--color-dark-grey);
    display: inline-block;
    transition: color 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        margin: 0 calc(15rem / 16);
        line-height: calc(40/28);
        font-size: calc(28rem / 16);
    }
}
.nav-tabs:not(.nav-no-border) .nav-link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    height: calc(3rem / 16);
    width: 50%;
    background: var(--color-primary);
    transform: translateX(-50%);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        height: calc(4rem / 16);
    }
}



.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    color: var(--color-text-default);
}
.nav-tabs .nav-link.active:after {
    transform: translateX(-50%);
}

.nav-fill.nav-tabs .nav-link {
    display: block;
    margin: 0;
}
