@media (min-width: 992px) {
    .intermap-margin{
        padding-left: 95px;
    }
}
@media (min-width: 1200px) {
    .intermap-margin{
        padding-left: 75px;
    }
}
