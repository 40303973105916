#job-form {
    #telphone {
        color: transparent;
        background: transparent;
        border: transparent;
    }

    .extra {
        color: transparent;
        background: transparent;
    }
}