.modal-content {
    border: 0;
}
.modal-header {
    padding: calc(25rem / 16) calc(20rem / 16) calc(5rem / 16);
}

@media screen and (min-width: 768px) {
    .modal-header {
        padding: calc(60rem / 16) calc(60rem / 16) calc(5rem / 16);
    }
}

.modal-body {
    padding: calc(25rem / 16) calc(20rem / 16);
}

@media screen and (min-width: 768px) {
    .modal-body {
        padding: calc(50rem / 16) calc(60rem / 16);
    }
}
.modal-header + .modal-body {
    padding-top: calc(20rem / 16);
}
.modal-close {
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    background: var(--color-primary);
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
}
.modal-close__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.modal-title__light {
    font-size: 3rem;
}

:root {
    --xxl-skimap-height: 42rem;
    --lg-skimap-height: 30rem;
    --md-skimap-height: 28rem;
}

.ski-map-modal .modal-content {
    position: relative;
    resize: both;

    @media screen and (min-width: 768px) {
        /*height: calc(var(--md-skimap-height) + 6rem);*/
        width: calc(2.07*var(--md-skimap-height));
    }
    @media screen and (min-width: 992px) {
        /*height: calc(var(--lg-skimap-height) + 8rem);*/
        width: calc(2.07*var(--lg-skimap-height));
    }
    @media screen and (min-width: 1400px) {
        /*height: calc(var(--xxl-skimap-height) + 10rem);*/
        width: calc(2.07*var(--xxl-skimap-height));
    }
}
.ski-map-modal .modal-body {
    margin: 0 auto;
    padding: 0.5rem;

    @media screen and (min-width: 768px) {
        height: var(--md-skimap-height);
        width: calc(2.07*var(--md-skimap-height));
    }
    @media screen and (min-width: 992px) {
        height: var(--lg-skimap-height);
        width: calc(2.07*var(--lg-skimap-height));
    }
    @media screen and (min-width: 1400px) {
        height: var(--xxl-skimap-height);
        width: calc(2.07*var(--xxl-skimap-height));
    }
}
.ski-map-modal .intermap-margin{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.ski-map-modal .modal-dialog {
    max-width: 100vw !important;
}