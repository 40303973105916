.slide-sm,
.slide.slide-sm {
    margin-top: calc(30rem/16);
    margin-bottom: calc(30rem/16);
}

.slide {
    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);
}

/* slides with background color / image */
.slide.slide[class*='bg-'] {
    margin: 0;
    padding-top: calc(60rem / 16);
    padding-bottom: calc(60rem / 16);
}

.slide.slide--border-top {
    border-top: calc(1rem / 16) solid var(--color-grey);
    margin: 0;
    padding-top: calc(60rem / 16);
    padding-bottom: calc(60rem / 16);
}

.slide.slide--drop-bg {
    position: relative;
    margin-bottom: 0;
    padding-bottom: calc(40rem / 16);
}

@media screen and (min-width: 1200px) {
    .slide.slide--drop-bg {
        padding-bottom: calc(110rem / 16);
    }
}

.slide--drop-bg:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(3.5rem + 43vw);
    background: url('/static/build/img/decorator-bg.png');
    background-color: var(--color-light-grey);
    z-index: -1;
    pointer-events: none;
}

@media screen and (min-width: 768px) {
    .slide-sm,
    .slide.slide-sm {
        margin-top: calc(95rem/16);
        margin-bottom: calc(95rem/16);
    }

    .slide {
        margin-top: calc(120rem/48);
        margin-bottom: calc(220rem/48);
    }

    .slide.slide[class*='bg-'] {
        padding-top: calc(110rem / 16);
        padding-bottom: calc(110rem / 16);
    }

    .slide.slide--border-top {
        margin: 0;
        padding-top: calc(95rem / 16);
        padding-bottom: calc(95rem / 16);
    }

    .slide.slide--drop-bg {
        margin-bottom: 0;
        padding-bottom: calc(70rem / 16);
    }

    .slide--drop-bg:before {
        height: calc(400rem / 16);
    }
}