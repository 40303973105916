.navbar__main {
    position: fixed;
    z-index: 7;
    bottom: 0;
    right: 0;
    left: 0;
    top: calc(60rem / 16);
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
    transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
    background-color: #fff;
    display: block;
}
@media screen and (min-width: 768px) and (max-height: 800px) {
    .navbar__main .nav-link {
        padding: calc(15rem / 16) 0;
    }
    .nav-item__dropdown .nav-link {
        font-size: calc(18rem / 16);
        padding: calc(8rem / 16) 0;
    }
}

@media screen and (min-width: 768px) {
    .navbar__main {
        padding: calc(55rem / 16) calc(70rem / 16);
        left: calc(70rem / 16);
        top: calc(70rem / 16);
    }
    .navbar__main:after {
        content: '';
        background: linear-gradient(180deg, #FFFFFF 30%, rgba(255,255,255,.4) 100%);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        min-height: calc(800rem / 16);
    }
    .navbar__main .navbar-nav {
        width: 42%;
    }
}

@media screen and (min-width: 1400px) {
    .navbar__main .navbar-nav {
        width: 35%;
    }
    .navbar__main .nav-link {
        font-size: calc(35rem / 16);
        padding: calc(30rem / 16) 0;
        padding-right: calc(100rem / 16);
    }
    .nav-item__dropdown .nav-link {
        padding: calc(10rem / 16) 0;
        font-size: calc(22rem / 16);
    }
}

@media screen and (min-width: 768px) and (max-height: 800px) and (max-width: 1399px) {
    .navbar__main {
        padding: calc(20rem / 16) 0;
    }
}

.is-open .navbar__main {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: transform 0.4s, opacity 0.4s;
}
.navbar__main .navbar-collapse {
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.navbar__main .navbar-nav {
    position: relative;
}
@media screen and (min-width: 768px) {
    .main__nav {
        margin: 0 5%;
        display: flex;
        justify-content: center;
        align-content: end;
    }
    .main__nav-item {
        /*margin-top: auto;*/
        font-size: 1.5rem;
        position: relative;
        width: 50px;
        font-family: var(--font-default-bold);
        flex-grow: 2;
        color: rgba(255, 255, 255, 0.7);
    }
    .main__nav-item-underline {
        width: 4rem;
        height: 4px;
        margin: auto auto 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.7);
    }
    .main__nav-item:hover .main__nav-item-underline {
        border-bottom-color: rgba(255, 255, 255, 1);
    }
    .main__nav-item:hover .nav-item__dropdown {
        visibility: visible;
        opacity: 1;
    }
    .main__nav-item:hover .main__item{
        background-color:  var(--color-primary);
        color: white;
    }
    .nav-item__dropdown {
        z-index: 10;
        display: block;
        background-color:  var(--color-primary);
        position: absolute;
        left: 0;
        top: 100%;
        opacity: 0;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        min-height: 100%;
        min-width: 100%;
        visibility: hidden;
    }
    .nav-item__dropdown .nav-item {
        position: relative;
        border-top: 1px solid rgba(90, 90, 90, 0.3);
        font-size: 1rem;
        font-family: var(--font-default-bold);
        font-weight: 900;
        background-color: white;
        color: black;
        text-align: start;
        align-items: center;
        align-content: center;
        transition: all 200ms ease-in-out;
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .nav-item__dropdown .sub-page-wrapper {
        display: none;
        top: 0;
        position: absolute;
        right: 0;
        color: black;
    }
    .main__nav-item:last-child .nav-item__dropdown .sub-page-wrapper {
        left: 0;
        transform: translateX(-100%);
    }

    .nav-item__dropdown .nav-item:hover {
        background-color:  var(--color-primary);
        a {
            background-color: var(--color-primary);
            transform: translateX(10%);
        }
        .sub-page-wrapper {
            display: block;
            color: black;

            a {
                background-color: white;
            }

            .nav-item:hover {
                background-color: var(--color-primary);

                a.nav-link {
                    background-color: var(--color-primary) !important;
                }
            }

            .nav-link {
                color: black;
            }
        }
    }

    .nav-item__dropdown .nav-link {
        line-height: 2rem;
        transition: all 200ms ease-in-out;
        display: flex;
        justify-content: start;
        align-content: center;
        align-items: center;
        gap: 1rem;
        margin: 0 2rem 0 1rem;
        font-size: 0.5rem;
        font-weight: 900;
        text-align: center;
        white-space: nowrap;
    }
    .nav-item__dropdown .nav-item:hover .nav-link {
        color:white;
    }

    .nav-item__dropdown .nav-item:hover .sub-page-wrapper .nav-link {
        color: black;
    }

    .nav-item__dropdown .sub-page-wrapper .nav-item:hover .nav-link {
        color: white;
    }

    .nav-item__dropdown .nav-link__icon {
        transform: rotate(-90deg);
        font-size: calc(10rem / 16);
        color: rgba(90, 90, 90, 0.5);
    }
    .nav-item__dropdown .nav-item:hover .nav-link__icon {
        color:white;
    }

    .nav-item__dropdown .nav-item:hover .sub-page-wrapper .nav-link__icon {
        color: rgba(90, 90, 90, 0.5);
    }

    .nav-item__dropdown .sub-page-wrapper .nav-item:hover .nav-link__icon {
        color: white;
    }
}

@media screen and (max-width: 767px) {
    .nav-link {
        width: 100%;
        display: flex;
    }
    .nav-link-icon-div {
        margin: auto 0 auto auto;
    }
    .nav-link__icon {
        transform: translateY(-50%) rotate(-90deg);
        font-size: calc(10rem / 16);
        margin: auto 0;
    }
    .nav-link__label {
        text-align: center;
        margin: auto 0;
    }
    .main-nav__label {
        font-family: var(--font-default-bold);
    }
    .nav-item__dropdown {
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
    }
    .nav-item.is-open > a .nav-icon-plus span {
        background: #000;
    }

    .nav-item__dropdown .nav-link {
        padding: 0;
        font-size: calc(20rem / 16);
        font-family: var(--font-default);
        font-weight: 400;
    }
    .nav-item__dropdown .nav-link__icon {
        font-size: calc(6rem / 16);
        right: calc(30rem / 16);
    }

    .nav-item__dropdown .nav-item__dropdown .nav-item {
        padding-right: 0;
    }
}
@media screen and (min-width: 768px) {
    /* .nav-item:hover > .nav-item__dropdown, */
    .nav-item.is-open > .nav-item__dropdown {
        transform: translateX(0) ;
        opacity: 1 ;
        visibility: visible;
    }
}
@media screen and (max-width: 767px) {
    .nav-item.is-open > .nav-item__dropdown {
        transform: translateX(0);
    }
    .nav-icon-plus {
        right: 0;
    }
}
.main__nav-item {
    @media screen and (max-width: 1400px) {
        font-size: 1.25rem;
    }
    @media screen and (max-width: 992px) {
        font-size: 1rem;
    }
}

.nav-icon-plus {
    width: 30px;
    height: 30px;
    border: 0;
    font-size: 1.5em;
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    display: inline-block;
    margin-left: 20px;
}

.nav-icon-plus--thinner {
    height: 20px;
    width: 20px;
    margin-left: 15px;
}

.nav-icon-plus span {
    position: absolute;
    transition: 300ms;
    background: rgb(129, 129, 129);
    border-radius: 2px;
}

/* Create the "+" shape by positioning the spans absolutely */
.nav-icon-plus span:first-child {
    top: 25%;
    bottom: 25%;
    width: 10%;
    left: 45%;
}

.nav-icon-plus span:last-child {
    left: 25%;
    right: 25%;
    height: 10%;
    top: 45%;
}

/* Morph the shape when the .nav-icon-plus is hovered over */
.nav-item.is-open > a .nav-icon-plus span {
    transform: rotate(90deg);
}

.nav-item.is-open > a .nav-icon-plus span:last-child {
    left: 50%;
    right: 50%;
}

.nav-link-bold {
    font-family: var(--font-default-bold);
    border-bottom: 2px solid #0b72b4;
    padding: .625rem 0;
    font-size: 1.38rem;
    color: #000;
}