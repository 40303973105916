.loisl-widget {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;

    .close-icon {
        position: absolute;
        top: -3.5rem;
        left: .75rem;
        cursor: pointer;
        padding: 1rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info-icon {
        position: absolute;
        top: 10%;
        right: -4rem;
        cursor: pointer;
        padding: 1rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
    }
}

.info-modal {
    position: fixed;
    bottom: 10%;
    left: 25%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    z-index: 1000;
    -webkit-box-shadow: 0 0 15px 5px rgba(0,0,0,0.30);
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.30);
    max-width: 30%;

    .cursor-pointer {
        cursor: pointer;
    }
}

@media only screen and (max-width: 576px) {
    .loisl-widget {
        bottom: 3rem;
        z-index: 1;

        .close-icon {
            transform: scale(.80);
        }

        .info-icon {
            transform: scale(.80);
        }
    }
    .info-modal {
        max-width: 95%;
        width: 95%;
        top: 35%;
        left: 50%;
        z-index: 900;
    }
}