/* only for slides ? */
#main-content.headerless .title-block{
    @media screen and (max-width:767px){
        margin-top: 120px;
    }
}
.title-block {
    margin-bottom: calc(35rem / 16);
    text-align: center;

    @media screen and (min-width: 768px) {
        margin-bottom: calc(72rem / 16);
    }
}
.text-left .title-block {text-align: left;}

.title-block--sm .title-block__title {
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(35rem / 16);
        line-height: calc(40/35);
    }
}
.title-block--sm .title-block {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(40rem / 16);
    }
}

.title-block--main {
    margin: calc(110rem / 16) 0 calc(55rem / 16);

    @media screen and (min-width: 768px) {
        margin: calc(110rem / 20) 0 calc(90rem / 20);
    }
}
.title-block--main + .slide {
    margin-top: 0;
}

.title-block__intro {
    position: relative;
}

.title-block__title ~ .title-block__intro:before,
.title-block__sub-title ~ .title-block__intro:before {
    content: '';
    display: inline-block;
    height: calc(3rem / 16);
    width: calc(134rem / 16);
    background: var(--color-primary);
    margin: calc(20rem / 16) 0 calc(10rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(6rem / 16);
        width: calc(207rem / 16);
    }
}

.title-block-img {
    max-width: 100%;
    margin: 0 auto;
    border-radius: 100%;
    transition: transform 300ms ease-in-out;
    width: calc(150rem / 16);
}
