/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Text Color Helper */
.text-primary-hover:hover { color: var(--color-primary); }
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }


/* Background Color Helper */
.bg-light-grey { background: var(--color-light-grey);}


/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.clickable {cursor: pointer;}

.position-relative { position: relative; }

.todo {
    text-align: center;
    background: orange;
    font-size: 2rem;
    font-family: var(--font-default-bold);
}


.link-icon {
    font-family: var(--font-default);
}
.link-icon__icon {
    font-size: calc(34rem / 16);
    vertical-align: -0.2em;
    display: block;
    margin: 0 auto calc(5rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0 calc(10rem / 16) 0 0;
        display: inline-block;
        font-size: calc(40rem / 16);
        vertical-align: -0.3em;
    }
}