.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item {
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item {
    margin-top: .5rem;
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item {
    margin-top: 3rem;
}

/* base grid gutter */
.vertical-gutter--base {
    margin-top: calc(-40rem / 16);
}
.vertical-gutter--base > .vertical-gutter__item {
    margin-top: calc(40rem / 16);
}

.vertical-gutter--flexible {
    margin-top: calc(-20rem / 16);

    @media screen and (min-width: 1200px) {
        margin-top: calc(-40rem / 16);
    }
}
.vertical-gutter--flexible > .vertical-gutter__item {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 1200px) {
        margin-top: calc(40rem / 16);
    }
}
