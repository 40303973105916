.download-item {
    font-size: calc(14rem / 16);
    box-shadow: 0 0 calc(10rem / 16) rgba(0,0,0,.1);
    background: #fff;

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        transition: color 200ms ease-in-out;
    }
}
.download-item:focus,
.download-item:hover {
    @media screen and (min-width: 768px){
        color: var(--color-primary);
    }
}
.download-item + .download-item {
    margin-top: calc(10rem / 16);
}
.download-item__content {
    padding: calc(8rem / 16) calc(12rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(18rem / 16) calc(40rem / 16) calc(18rem / 16) calc(24rem / 16);
    }
}
.download-item__title{
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.download-item__icon {
    font-size: calc(22rem / 16);
    margin-right: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(28rem / 16);
        margin-right: calc(18rem / 16);
    }
}
.download-item__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: calc(10rem / 16);
    padding: calc(8rem / 16) calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(8rem / 16);
        font-size: calc(12rem / 16);
        min-width: calc(85rem / 16);
    }
}
.download-item__btn__icon{
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}

.download-item__meta {
    position: relative;
}
.download-item__meta + .download-item__meta {
    margin-left: calc(10rem / 16);
    padding-left: calc(10rem / 16);
}
.download-item__meta + .download-item__meta:before {
    content: '';
    position: absolute;
    top: calc(4rem / 16);
    left: 0;
    bottom: calc(4rem / 16);
    background: currentColor;
    width: calc(1rem / 16);
}