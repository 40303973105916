/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a6c87");

@font-face {
    font-family: 'BrandonGrotesqueWeb-Regular';
    src: url('/static/fonts/3A6C87_1_0.woff2') format('woff2'),
    url('/static/fonts/3A6C87_1_0.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'BrandonGrotesqueWeb-Black';
    src: url('/static/fonts/3A6C87_0_0.woff2') format('woff2'),
    url('/static/fonts/3A6C87_0_0.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'ArapeyItalic';
    src: url('/static/fonts/ArapeyItalic.woff2') format('woff2'),
    url('/static/fonts/ArapeyItalic.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}