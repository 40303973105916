.percent-circle {
    transform: scale(.5);
    transform-origin: center center;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    opacity: 0;
}
.is-animated.percent-circle {
    opacity: 1;
    transform: scale(1);
}
.percent-circle__text {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-size: calc(16rem / 16);
}
.percent-circle__text__current {
    font-size: calc(30rem / 16);
}
.percent-circle__svg {
    transform: rotate(-90deg) translateZ(0);
    margin: 0 auto;
    display: block;
}
.percent-circle__svg__bar{
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 250ms ease-in-out;
    transition-delay: 220ms;
    stroke-width: calc(1rem / 16);
    stroke: currentColor;
    stroke-opacity: .6;
}
.percent-circle__svg__bar--progress {
    stroke-width: calc(7rem / 16);
    stroke: var(--color-primary);
    stroke-opacity: 1;
}