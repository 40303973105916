.video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
    transition: background 200ms ease-in-out;
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    padding: 0;
    background: var(--color-primary);
    border: calc(1rem / 16) solid var(--color-dark);
    border-radius: 50%;

    @media screen and (min-width: 768px) {
        width: calc(82rem / 16);
        height: calc(82rem / 16);
    }
}
.video-js .vjs-big-play-button:after {
    content: '';
    position: absolute;
    top: calc(-14rem / 16);
    bottom: calc(-14rem / 16);
    left: calc(-14rem / 16);
    right: calc(-14rem / 16);
    border: calc(1rem / 16) solid var(--color-dark);
    border-radius: 50%;
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        top: calc(-20rem / 16);
        bottom: calc(-20rem / 16);
        left: calc(-20rem / 16);
        right: calc(-20rem / 16);
    }
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    position: absolute;
    content: var(--icon-play);
    font-family: iconfont;
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;
    font-size: calc(26rem / 16);
    transform: translateX(-35%) translateY(-50%);

    @media screen and (min-width: 768px) {
        font-size: calc(33rem / 16);
    }
}
.video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button {
    background: var(--color-primary);
    border-color: var(--color-dark);
    transition: background 200ms ease-in-out;
}
.video-js .vjs-big-play-button:focus:after, .video-js:hover .vjs-big-play-button:after {
    transform: scale(.9);
}
.video-js .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.5);
}
.video-js .vjs-slider {
    background-color: rgba(255,255,255,.5);
}

/*.video-overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 1;
    transition: opacity 200ms ease-in-out;
}
.video-overlay__title {
    font-size: calc(30rem / 16);
    line-height: calc(40/50);

    @media screen and (min-width: 768px) {
        font-size: calc(50rem / 16);
    }
}
.vjs-has-started + .video-overlay {
    opacity: 0;
}*/
/*
.video-js--hide-play-btn .vjs-big-play-button {
    display: none;
}*/
