.custom-radio{
    position: relative;
    padding-left: calc(20rem/16);
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: calc(1rem/16) solid #c4c6c7;
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .1em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
    position: relative;
}

/* radio button styled like links */
.custom-radio--text {
    padding: 0;
    display: inline-block;
    font-size: calc(22rem / 16);
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        font-size: calc(28rem / 16);
    }
}
.custom-radio--text:not(:last-child) {
    margin-right: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(30rem / 16);
    }
    @media screen and (min-width: 1200px) {
        margin-right: calc(100rem / 16);
    }
}
.custom-radio--text .custom-radio__box {
    display: none;
}
.custom-radio--text .custom-radio__text:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(4rem / 16);
    background: var(--color-primary);
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
}
.custom-radio__input:checked ~ .custom-radio__text {
    color: var(--color-default);
}
.custom-radio--text:hover .custom-radio__text:after,
.custom-radio__input:focus ~ .custom-radio__text:after,
.custom-radio__input:checked ~ .custom-radio__text:after {
    transform: scaleX(1);
}