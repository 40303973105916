.icon-in-text {
    vertical-align: -.12em;
}
.icon-in-text--lg {
    font-size: calc(40rem / 16);
    vertical-align: -0.3em;
    margin-right: calc(10rem / 16);
}
.icon-in-text--sm {
    font-size: .8em;
}
.icon-in-text--width {
    width: calc(32rem / 16);
    text-align: center;
    font-size: calc(26rem / 16);
    vertical-align: -.15em;
}
/* Icons specific placement */

/*.icon-in-text.icon-arrow-right {
    vertical-align: -.2em;
}*/