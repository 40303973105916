:root {
    --color-primary: #0B72B5;
    --color-secondary: #97BF0D;
    --color-text-default: #000;
    --color-text-muted: #868E96;

    --color-default: #000;
    --color-white: #FFF;
    --color-light-grey: #F0F1F1;
    --color-grey: #EBECEC;
    --color-dark-grey: #9C9E9F;
    --color-dark: #111418;

    --color-success: #97BF0D;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #F95500;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #0a67a3;
    --color-secondary-dark: #88ac0c;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #0c7cc5;
    --color-secondary-light: #addb0f;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --color-ehrwald: #A0BE32;
    --color-lermoos: #E6AF14;
    --color-berwang: #BE9D64;
    --color-bichlbach: #9C9C9C;
    --color-biberwier: #7D1978;
    --color-heiterwang: #9B1E28;

    --font-default: "BrandonGrotesqueWeb-Regular", sans-serif;
    --font-default-bold: "BrandonGrotesqueWeb-Black", sans-serif;
    --font-default-medium: "BrandonGrotesqueWeb-Black", sans-serif;
    --font-arapey: "ArapeyItalic", sans-serif;

    --font-size-default: calc(20rem / 16);
}
