.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-small);
    color: var(--color-primary);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(6rem / 16);
    top: 50%;
    right: calc(2rem / 16);
    transform: translateY(-50%);
    z-index: 1;
}
select.form-control:not([size]):not([multiple]) {
    height: calc(38rem / 16);
    padding: calc(6rem / 16) 0 0;

    @media screen and (min-width: 768px) {
        height: calc(48rem / 16);
    }
}
.select--bordered:before {
    right: calc(20rem / 16);
}
select.form-control--bordered:not([size]):not([multiple]) {
    padding: calc(8rem / 16) calc(20rem / 16);
}
