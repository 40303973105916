.iframe-content {
    position: relative;
}
.iframe-content__overlay {
    background: rgba(255,255,255,.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 200ms ease-in-out;
}
.iframe-content.is-active .iframe-content__overlay {
    opacity: 0;
    visibility: hidden;
}