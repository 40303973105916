.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

/* table */
.wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    font-size: calc(16rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);
    }

    @media screen and (max-width: 767px) {
        display: block;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}
.wysiwyg table thead th,
.table thead th {
    background: var(--color-light-grey);
}
.wysiwyg table thead th,
.table thead th {
    vertical-align: top;
    font-family: var(--font-default-bold);
    font-weight: 400;
    padding: calc(14rem / 16);
    border: calc(1rem / 16) solid var(--color-default);

    @media screen and (min-width: 1200px) {
        padding: calc(18rem / 16) calc(40rem / 16);
        font-size: calc(20rem / 16);
        line-height: calc(24/20);
    }
}
.wysiwyg table td, .wysiwyg table th,
.table td, .table th {
    font-family: var(--font-default);
    font-weight: 400;
    border: calc(1rem / 16) solid var(--color-default);
    padding: calc(10rem / 16) calc(14rem / 16) calc(8rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(12rem / 16) calc(40rem / 16) calc(10rem / 16);
    }
}

.wysiwyg ul {
    list-style: none;
    padding-left: 0;
}
.wysiwyg ul li {
    padding-left: calc(18rem / 16);
    position: relative;
}
.wysiwyg ul li:before {
    content: '';
    position: absolute;
    top: calc(12rem / 16);
    left: 0;
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    background: var(--color-primary);
}
.wysiwyg ul li ul li {
    padding-left: calc(12rem / 16);
}
.wysiwyg ul li ul li:before {
    top: calc(12rem / 16);
    width: calc(6rem / 16);
    height: calc(6rem / 16);
}

.pimcore_area_content-wysiwyg-area .wysiwyg-area:not(.text-left) .wysiwyg ul, .wysiwyg-area:not(.text-left) .wysiwyg ol{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
