.snow-info {
    @media screen and (max-width: 767px) {
        margin-right: calc(-200rem / 16);
        margin-left: calc(-10rem / 16);
    }
}
.snow-info__mountain {
    fill:#9C9E9F;
}
.snow-info__line {
    stroke:#000000;
    stroke-width:0.25;
    stroke-miterlimit:10;
}
.snow-info .st2 { fill:none; }
.snow-info__value {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-size: calc(11rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(8rem / 16);
    }
}
.snow-info__label{
    font-family:var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: calc(10rem / 16);
}