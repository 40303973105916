.offset-slider-container .slick-slider {
    margin: 0 calc(-15rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0 calc(-20rem / 16);
    }
}
.offset-slider-container .slick-slide {
    padding: 0 calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(20rem / 16);
    }
}
.offset-slider {
    max-width: calc(1636rem / 16);
    margin-left: auto;
    width: 100%;
    position: relative;
}
.offset-slider-container {
    overflow: hidden;
    padding-left: calc(40rem / 16);
    padding-right: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(30rem / 16);
        padding-right: calc(30rem / 16);
    }
}
.offset-slider-container .slick-list {
    width: 100%;
    overflow: visible;
    padding-bottom: 0;

    @media screen and (min-width: 768px) {
        padding-bottom: 0;
    }
}

.offset-slider .slick-arrow {
    color: var(--color-primary);
    outline: var(--color-primary);
}
@media only screen and (max-width: 766px){
    .offset-slider .slick-arrow {
        display: none !important;
    }
}
.offset-slider .slick-prev {
    opacity: 1 !important;
    left: -10px !important;
    background-color: white;
}
.offset-slider .slick-next {
    opacity: 1 !important;
    right: -10px !important;
    background-color: white;
}

.offset-slider .slick-arrow[aria-disabled="true"] {
    opacity: 0.5 !important;
}