.badge {
    line-height: 1;
    font-size: calc(18rem / 16);
    padding: calc(6rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        padding: calc(10rem / 16) calc(15rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(25rem / 16);
        padding: calc(12rem / 16) calc(20rem / 16);
    }
}

.badge-outline-default {
    border: calc(1rem / 16) solid var(--color-default);
    background: transparent;
}

/* badge sizes */
.badge.badge--sm {
    font-size: calc(16rem / 16);
    padding: calc(4rem / 16) calc(7rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        padding: calc(7rem / 16) calc(10rem / 16);
    }
}